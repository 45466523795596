html {
  scroll-behavior: smooth;
}

a:not(.bm-item) {
  background-color: rgba(149, 194, 61, 0.3);
  color: inherit;
}
a:not(.bm-item):hover,
a:not(.bm-item):active {
  background-color: rgba(149, 194, 61, 0.6);
  font-weight: 600;
}

strong,
b  {
  font-weight: 500;
}
